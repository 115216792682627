import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, usePagination, ButtonModifier } from "../../../components";
import { ShowTableJurnalUmum } from "../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Pagination } from "@mui/material";

const TampilJurnalUmum = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [noBukti, setNoBukti] = useState("");
  const [tglJurnalUmum, setTglJurnalUmum] = useState("");
  const [tglJurnalUmumDate, setTglJurnalUmumDate] = useState();
  const [totalDebet, setTotalDebet] = useState("");
  const [totalKredit, setTotalKredit] = useState("");
  const [isPost, setIsPost] = useState("");

  const [jurnalUmumsChildData, setJurnalUmumsChildData] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager =
    user.tipeUser !== "ADMIN" &&
    user.tipeUser !== "OWN/DIREKSI" &&
    isPost === false;
  let isEditableAdmin =
    user.tipeUser === "ADMIN" &&
    user.tipeUser !== "OWN/DIREKSI" &&
    tglJurnalUmumDate?.getFullYear() === today.getFullYear() &&
    tglJurnalUmumDate?.getMonth() === today.getMonth() &&
    tglJurnalUmumDate?.getDate() === today.getDate() &&
    isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = jurnalUmumsChildData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const count = Math.ceil(jurnalUmumsChildData.length / PER_PAGE);
  const _DATA = usePagination(jurnalUmumsChildData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id && getJurnalUmumById();
  }, []);

  const getJurnalUmumById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/jurnalUmums/${id}`, {
      id: user._id,
      token: user.token,
    });
    setNoBukti(response.data.noBukti);
    setTglJurnalUmum(formatDate(response.data.tglJurnalUmum));
    setTglJurnalUmumDate(new Date(response.data.tglJurnalUmum));
    setIsPost(response.data.isPost);
    setTotalDebet(response.data.totalDebet);
    setTotalKredit(response.data.totalKredit);
    const response2 = await axios.post(`${tempUrl}/jurnalUmumsChildByNoBukti`, {
      noBukti: response.data.noBukti,
      kodeCabang: response.data.cabang,
      id: user._id,
      token: user.token,
    });
    setJurnalUmumsChildData(response2.data);
    setLoading(false);
  };

  const deleteJurnalUmum = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteJurnalUmumTransaction`, {
        jurnalUmumData: {
          idJurnalUmum: id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        },
        jurnalUmumChildData: {
          noBukti,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        },
        noBukti,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      navigate("/daftarJurnalUmum");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Jurnal Umum</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (totalDebet - totalKredit === 0) {
            navigate("/daftarJurnalUmum");
          } else {
            alert(`No. Bukti ${noBukti} ini tidak BALANCE!`);
          }
        }}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={buttonModifierContainer}>
        {isEditable && (
          <>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarJurnalUmum/jurnalUmum/${id}/tambahJurnalUmumChild`}
              editLink={`/daftarJurnalUmum/jurnalUmum/${id}/edit`}
              deleteUser={deleteJurnalUmum}
              nameUser={noBukti}
            />
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Jurnal Umum</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jurnal Umum :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tglJurnalUmum}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Debet :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalDebet.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Kredit :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalKredit.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJurnalUmum id={id} currentPosts={currentPosts} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilJurnalUmum;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
