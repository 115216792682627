import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const TampilJurnalUmumChild = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const { id, idJurnalUmumChild } = useParams();
  const [tempIdJurnalUmumChild, setTempIdJurnalUmumChild] = useState("");
  const [noBukti, setNoBukti] = useState("");
  const [tglJurnalUmum, setTglJurnalUmum] = useState("");
  const [tglJurnalUmumDate, setTglJurnalUmumDate] = useState();
  const [keterangan, setKeterangan] = useState("");
  const [debet, setDebet] = useState("");
  const [kredit, setKredit] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [isPost, setIsPost] = useState("");

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let today = new Date();
  let isEditableManager =
    user.tipeUser !== "ADMIN" &&
    user.tipeUser !== "OWN/DIREKSI" &&
    isPost === false;
  let isEditableAdmin =
    user.tipeUser === "ADMIN" &&
    user.tipeUser !== "OWN/DIREKSI" &&
    tglJurnalUmumDate?.getFullYear() === today.getFullYear() &&
    tglJurnalUmumDate?.getMonth() === today.getMonth() &&
    tglJurnalUmumDate?.getDate() === today.getDate() &&
    isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getJurnalUmumChildById();
  }, []);

  const getJurnalUmumChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/jurnalUmumsChild/${idJurnalUmumChild}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setTempIdJurnalUmumChild(response.data._id);
      setNoBukti(response.data.noBukti);
      setTglJurnalUmum(formatDate(response.data.tglJurnalUmum));
      setTglJurnalUmumDate(response.data.tglJurnalUmum);
      setKodeCOA(`${response.data.COA.kodeCOA} - ${response.data.COA.namaCOA}`);
      setKeterangan(response.data.keterangan);
      setIsPost(response.data.isPost);
      setDebet(response.data.debet);
      setKredit(response.data.kredit);
    }
  };

  const deleteJurnalUmumChild = async (id) => {
    try {
      setLoading(true);
      const pickedJurnalUmum = await axios.post(
        `${tempUrl}/jurnalUmums/${id}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      let tempTotalDebet =
        parseInt(pickedJurnalUmum.data.totalDebet) - parseInt(debet);
      let tempTotalKredit =
        parseInt(pickedJurnalUmum.data.totalKredit) - parseInt(kredit);
      await axios.post(`${tempUrl}/deleteJurnalUmumChildTransaction`, {
        jurnalUmumData: {
          idJurnalUmum: id,
          totalDebet: tempTotalDebet,
          totalKredit: tempTotalKredit,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        },
        jurnalUmumChildData: {
          idJurnalUmumChild: tempIdJurnalUmumChild,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      navigate(`/daftarJurnalUmum/jurnalUmum/${id}`);
    } catch (error) {
      alert(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Detail Jurnal Umum</h5>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarJurnalUmum/jurnalUmum/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={deleteButtonContainer}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Yakin ingin menghapus data ${noBukti}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteJurnalUmumChild(id)}>Ok</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {isEditable && (
          <ButtonGroup variant="contained">
            <Button
              color="primary"
              startIcon={<EditIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => {
                navigate(
                  `/daftarJurnalUmum/jurnalUmum/${id}/${idJurnalUmumChild}/edit`
                );
              }}
            >
              Ubah
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteOutlineIcon />}
              sx={{ textTransform: "none" }}
              onClick={handleClickOpen}
            >
              Hapus
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <hr />
      <Card>
        <Card.Header>Detail Jurnal Umum</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode COA :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={kodeCOA} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={tglJurnalUmum}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Debet :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={debet.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kredit :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={kredit.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TampilJurnalUmumChild;

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
