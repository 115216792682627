import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTableTitip } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Chip,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TampilTitip = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isTitipsExist, setIsTitipsExist] = useState(false);
  const [noTitip, setNoTitip] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tglPenarikan, setTglPenarikan] = useState("");
  const [tglBatasWaktu, setTglBatasWaktu] = useState("");
  const [statusStnk, setStatusStnk] = useState("");
  const [tglJTOPajak, setTglJTOPajak] = useState("");
  const [statusKunciMotor, setStatusKunciMotor] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [nopol, setNopol] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [namaKolektor, setNamaKolektor] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [titipsData, setTitipsData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [dariTgl, setDariTgl] = useState(new Date());
  const [sampaiTgl, setSampaiTgl] = useState(new Date());
  const [toggleCetak, setToggleCetak] = useState(false);

  const columnsCetak = [
    { title: "No.", field: "no" },
    { title: "Nama Konsumen", field: "namaRegister" },
    { title: "No Polisi", field: "nopol" },
    { title: "Tgl. Tarik", field: "tglPenarikanFormatted" },
    { title: "Batas Waktu", field: "tglBatasWaktuFormatted" },
    { title: "Stnk", field: "statusStnk" },
    { title: "Tgl. JTO Pajak", field: "tglJTOPajakFormatted" },
    { title: "Kunci Motor", field: "statusKunciMotor" },
    { title: "Marketing", field: "namaMarketing" },
    { title: "Surveyor", field: "namaSurveyor" },
    { title: "Eksekutor", field: "namaKolektor" },
    { title: "No. Jual", field: "noJual" },
  ];

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getTitipById() : setIsTitipsExist(false);
  }, [id]);

  useEffect(() => {
    if (user.tipeUser === "OWN/DIREKSI") {
      getTitipAllCabangsData();
    } else {
      getTitipsData();
    }
  }, [value, page, searchTerm]);

  const getTitipAllCabangsData = async () => {
    setLoading(true);
    try {
      let allTitips;
      switch (value) {
        case "Penarikan":
          allTitips = await axios.post(
            `${tempUrl}/titipAllCabangsPaginationPenarikan?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        case "Titip":
          allTitips = await axios.post(
            `${tempUrl}/titipAllCabangsPaginationNoPenarikan?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        default:
          allTitips = await axios.post(
            `${tempUrl}/titipAllCabangsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
      }

      setQuery(searchTerm);
      setTitipsData(allTitips.data.titips);
      setPage(allTitips.data.page);
      setPages(allTitips.data.totalPage);
      setRows(allTitips.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getTitipsData = async () => {
    setLoading(true);
    try {
      let allTitips;
      switch (value) {
        case "Penarikan":
          allTitips = await axios.post(
            `${tempUrl}/titipsPaginationPenarikan?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Titip":
          allTitips = await axios.post(
            `${tempUrl}/titipsPaginationNoPenarikan?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        default:
          allTitips = await axios.post(
            `${tempUrl}/titipsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
      }

      setQuery(searchTerm);
      setTitipsData(allTitips.data.titips);
      setPage(allTitips.data.page);
      setPages(allTitips.data.totalPage);
      setRows(allTitips.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getTitipById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/titips/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsTitipsExist(true);
      setNoTitip(response.data.noTitip);
      setNoJual(response.data.noJual);
      setTglPenarikan(formatDate(response.data.tglPenarikan));
      setTglBatasWaktu(formatDate(response.data.tglBatasWaktu));
      setStatusStnk(response.data.statusStnk);
      setTglJTOPajak(formatDate(response.data.tglJTOPajak));
      setStatusKunciMotor(response.data.statusKunciMotor);
      setNamaRegister(response.data.namaRegister);
      setNopol(response.data.nopol);
      setKodeMarketing(
        `${response.data.idJual.kodeMarketing.kodeMarketing} - ${response.data.idJual.kodeMarketing.namaMarketing}`
      );
      setKodeSurveyor(
        `${response.data.idJual.kodeSurveyor.kodeSurveyor} - ${response.data.idJual.kodeSurveyor.namaSurveyor}`
      );
      setNamaKolektor(response.data.kodeKolektor.namaKolektor);
      setKodeKolektor(
        `${response.data.kodeKolektor.kodeKolektor} - ${response.data.kodeKolektor.namaKolektor}`
      );
    }
  };

  const deleteTitip = async (id) => {
    try {
      setLoading(true);

      // Find Jual
      const findJualByNoJual = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      // Delete Titip Transaction
      await axios.post(`${tempUrl}/deleteTitipTransaction`, {
        titipData: {
          idTitip: id,
          id: user._id,
          token: user.token,
        },
        jualData: {
          idJual: findJualByNoJual.data._id,
          isTitip: false,
          tglTitip: null,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        stData: {
          idJual: findJualByNoJual.data._id,
          isTitip: false,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        id: user._id,
        token: user.token,
      });

      setNoTitip("");
      setNoJual("");
      setNamaRegister("");
      setNopol("");
      setTglPenarikan("");
      setTglBatasWaktu("");
      setStatusStnk("");
      setTglJTOPajak("");
      setStatusKunciMotor("");
      setKodeMarketing("");
      setKodeSurveyor("");
      setNamaKolektor("");
      setKodeKolektor("");
      setLoading(false);
      navigate("/titip");
      getTitipsData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdfCetak = async () => {
    setLoading(true);
    let allTitips;
    switch (value) {
      case "Penarikan":
        allTitips = await axios.post(`${tempUrl}/titipsPenarikanTanggal`, {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        break;
      case "Titip":
        allTitips = await axios.post(`${tempUrl}/titipsNoPenarikanTanggal`, {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        break;
      default:
        allTitips = await axios.post(`${tempUrl}/titipsTanggal`, {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        break;
    }

    const doc = new jsPDF("l", "mm", [297, 210]);
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Titip Motor`, 140, 30);
    doc.setFontSize(10);

    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columnsCetak.map((col) => ({ ...col, dataKey: col.field })),
      body: allTitips.data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
  };

  const downloadPdf = async () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Titip Motor`, 95, 30);
    doc.setFontSize(10);

    let tempY = 40;
    let tempX1 = 15;
    let tempX2 = 43;

    doc.text(`Nama Konsumen`, tempX1, tempY);
    doc.text(`: ${namaRegister}`, tempX2, tempY);
    tempY += 5;
    doc.text(`No Polisi`, tempX1, tempY);
    doc.text(`: ${nopol}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Tgl. Tarik`, tempX1, tempY);
    doc.text(`: ${tglPenarikan}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Batas Waktu`, tempX1, tempY);
    doc.text(`: ${tglBatasWaktu}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Stnk Ada`, tempX1, tempY);
    doc.text(`: ${statusStnk}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Tgl. JTO Pajak`, tempX1, tempY);
    doc.text(`: ${tglJTOPajak}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Kunci Motor Ada`, tempX1, tempY);
    doc.text(`: ${statusKunciMotor}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Marketing`, tempX1, tempY);
    doc.text(`: ${kodeMarketing}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Surveyor`, tempX1, tempY);
    doc.text(`: ${kodeSurveyor}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Executor`, tempX1, tempY);
    doc.text(`: ${kodeKolektor}`, tempX2, tempY);

    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Titip
      </Typography>
      <Box sx={downloadButtons}>
        {user.tipeUser !== "OWN/DIREKSI" && (
          <ButtonGroup variant="outlined" color="secondary">
            {isTitipsExist && (
              <Button startIcon={<PrintIcon />} onClick={() => downloadPdf()}>
                CETAK
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => setToggleCetak(!toggleCetak)}
            >
              Cetak
            </Button>
          </ButtonGroup>
        )}
      </Box>
      {toggleCetak && (
        <>
          <Box sx={spacingTop}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Dari Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={dariTgl}
                      onChange={(e) => setDariTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sampai Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTgl}
                      onChange={(e) => setSampaiTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                  <Col sm="4">
                    <Button
                      variant="contained"
                      startIcon={<PrintIcon />}
                      onClick={() => {
                        if (dariTgl === null || sampaiTgl == null) {
                          alert("Input Tanggal harus diisi!");
                        } else {
                          downloadPdfCetak();
                        }
                      }}
                    >
                      CETAK
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <hr />
        </>
      )}
      <Box sx={buttonModifierContainer}>
        {user.tipeUser !== "OWN/DIREKSI" && (
          <ButtonModifier
            id={id}
            kode={user.tipeUser === "MGR" && isTitipsExist}
            addLink={`/titip/tambahTitip`}
            editLink={`/titip/${id}/edit`}
            deleteUser={deleteTitip}
            nameUser={noJual}
          />
        )}
      </Box>
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Penarikan"
            control={<Radio />}
            label="Penarikan"
          />
          <FormControlLabel value="Titip" control={<Radio />} label="Titip" />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Divider sx={dividerStyle} />
      {isTitipsExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Titip :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noTitip} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglPenarikan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Batas Waktu :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglBatasWaktu} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Status STNK :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={statusStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. JTO Pajak :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglJTOPajak} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Status Kunci Motor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={statusKunciMotor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Surveyor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeSurveyor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Eksekutor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeKolektor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Penarikan"
          sx={{
            backgroundColor: Colors.yellow400,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTableTitip currentPosts={titipsData} tipeUser={user.tipeUser} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilTitip;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
