import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahSuratPemberitahuan = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  var date = new Date();

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [juals, setJuals] = useState([]);
  const [noJual, setNoJual] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tenor, setTenor] = useState("");
  const [bulan, setBulan] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [inputTglSp, setInputTglSp] = useState(date);
  const [spKe, setSpKe] = useState("");

  const [kodeKolektor, setKodeKolektor] = useState("");
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [nopol, setNopol] = useState("");
  const [tglMdTerakhir, setTglMdTerakhir] = useState("");
  const [tglMdTerakhirFormatted, setTglMdTerakhirFormatted] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [kolektors, setKolektors] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermJual, setSearchTermJual] = useState("");
  const [openJual, setOpenJual] = useState(false);
  const [openMd, setOpenMd] = useState(false);
  const [openJt, setOpenJt] = useState(false);
  const [openSpLebih, setOpenSpLebih] = useState(false);
  const [validated, setValidated] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermJual(query);
  };

  const handleClickOpenJual = () => {
    setOpenJual(true);
  };

  const handleCloseJual = () => {
    setOpenJual(false);
  };

  const handleClickOpenMd = () => {
    setOpenMd(true);
  };

  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const handleClickOpenJt = () => {
    setOpenJt(true);
  };

  const handleCloseJt = () => {
    setOpenJt(false);
  };

  const handleClickOpenSpLebih = () => {
    setOpenSpLebih(true);
  };

  const handleCloseSpLebih = () => {
    setOpenSpLebih(false);
  };

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getKolektor();
  }, []);

  useEffect(() => {
    getJual();
  }, [page, searchTermJual]);

  const getKolektor = async () => {
    setLoading(true);
    const allKolektors = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(allKolektors.data);
    setLoading(false);
  };

  const getJual = async () => {
    const allJualsForAngsuran = await axios.post(
      `${tempUrl}/jualsForAngsuranPagination?search_query=${searchTermJual}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setJuals(allJualsForAngsuran.data.juals);
    setPage(allJualsForAngsuran.data.page);
    setPages(allJualsForAngsuran.data.totalPage);
    setRows(allJualsForAngsuran.data.totalRows);
  };

  const saveSp = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let tglSp =
      inputTglSp.getFullYear() +
      "-" +
      (inputTglSp.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      inputTglSp.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

    let isFailedValidation =
      noJual.length === 0 || kodeKolektor.length === 0 || inputTglSp === null;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      var tempTglSp = new Date(tglSp);
      var tempTglAng = new Date(tglAng);
      if (tempTglSp < tempTglAng) {
        handleClickOpenSpLebih();
      } else {
        try {
          setLoading(true);
          const tempKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
            kodeKolektor,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          // Find Jual
          const response = await axios.post(`${tempUrl}/jualByNoJual`, {
            noJual,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          // Save Sp And Update Jual
          await axios.post(`${tempUrl}/saveSpAndUpdateJual`, {
            jualData: {
              idJual: response.data._id,
              noJual,
              spKe,
              tglSpTerakhir: tglSp,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            },
            spData: {
              noJual,
              tglSp,
              spKe,
              kodeKolektor: tempKolektor.data._id,
              idJual: response.data._id,
              tglInput: current_date,
              jamInput: current_time,
              userInput: user.username,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            },
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          setLoading(false);
          navigate("/suratPemberitahuan");
        } catch (error) {
          console.log(error);
        }
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Entry Surat Pemberitahuan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveSp}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={noJual}
                        onClick={() => handleClickOpenJual()}
                        readOnly
                        required
                        placeholder="Pilih..."
                      />
                      <Form.Control.Feedback type="invalid">
                        No. Jual harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Angsuran :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglAngFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angs. / Bulan / Sisa :
                    </Form.Label>
                    <Col sm={3}>
                      <Form.Control value={tenor} disabled />
                    </Col>
                    <Col sm={2}>
                      <Form.Control value={bulan} disabled />
                    </Col>
                    <Col sm={3}>
                      <Form.Control value={sisaBulan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Sp / Ke <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm={4}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={inputTglSp}
                        onChange={(e) => setInputTglSp(e)}
                        customInput={
                          <TextField
                            error={error && inputTglSp === null && true}
                            helperText={
                              error &&
                              inputTglSp === null &&
                              "Tgl. SP / Ke harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={spKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Eksekutor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={kolektorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeKolektor.length === 0 && true}
                            helperText={
                              error &&
                              kodeKolektor.length === 0 &&
                              "Kode Eksekutor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeKolektor(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Minta Waktu Terakhir :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglMdTerakhirFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun / Warna :
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={tahun} disabled />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/suratPemberitahuan")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={
                inputTglSp < tglMdTerakhir && inputTglSp < tglAng ? true : false
              }
            >
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openJual}
        onClose={handleCloseJual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jual`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Plat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Alamat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {juals.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoJual(user.noJual);
                        setNamaRegister(user.namaRegister);
                        setAlmRegister(user.almRegister);
                        setTglAng(user.tglJatuhTempo);
                        setTglAngFormatted(formatDate(user.tglJatuhTempo));
                        setTenor(user.tenor);
                        setBulan(user.tenor - user.sisaBulan);
                        setSisaBulan(user.sisaBulan);
                        setSpKe(user.spKe + 1);
                        setTipe(user.tipe);
                        setNoRangka(user.noRangka);
                        setNamaWarna(user.namaWarna);
                        setTahun(user.tahun);
                        setNopol(user.nopol);
                        setTglMdTerakhir(user.tglMdTerakhir);
                        setTglMdTerakhirFormatted(
                          formatDate(user.tglMdTerakhir)
                        );
                        setTglJatuhTempo(user.tglJatuhTempo);
                        user.tglMdTerakhir !== null && handleClickOpenMd();
                        date < new Date(user.tglJatuhTempo) &&
                          handleClickOpenJt();
                        handleCloseJual();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noJual}
                      </TableCell>
                      <TableCell>
                        {user.tenor - user.bayarKe !== 0 ? "MASIH" : "SELESAI"}
                      </TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                      <TableCell>{user.tanggalJualFormatted}</TableCell>
                      <TableCell>{user.nopol}</TableCell>
                      <TableCell>{user.almRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMd}
        onClose={handleCloseMd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Konsumen Minta Waktu`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Ada Minta Waktu ya, Tanggal ${tglMdTerakhir}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMd}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openJt}
        onClose={handleCloseJt}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Belum Jatuh Tempo`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`No. Jual ${noJual} ini blm ada yang Jatuh Tempo`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJt}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSpLebih}
        onClose={handleCloseSpLebih}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Tgl. SP Lebih Kecil dari Tgl. Angsuran`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {inputTglSp &&
              `${inputTglSp.getDate()}-${
                inputTglSp.getMonth() + 1
              }-${inputTglSp.getFullYear()} < ${tglAngFormatted}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSpLebih}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahSuratPemberitahuan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
