import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Paper,
  Snackbar,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import { formatDate } from "../../../constants/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditIcon from "@mui/icons-material/Edit";

const UbahBeliChild = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { id, idBeliChild } = useParams();
  const navigate = useNavigate();
  const [noBeli, setNoBeli] = useState("");
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [namaWarnaLama, setNamaWarnaLama] = useState("");
  const [kodeTipeLama, setKodeTipeLama] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noRangkaLama, setNoRangkaLama] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [noMesinLama, setNoMesinLama] = useState("");
  let [nopol, setNopol] = useState("");
  const [nopolLama, setNopolLama] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [tglStnk, setTglStnk] = useState(new Date());
  const [jenisABeli, setJenisABeli] = useState("");
  const [hargaSatuan, setHargaSatuan] = useState("");
  const [hargaSatuanLama, setHargaSatuanLama] = useState("");
  const [ppnABeli, setPpnABeli] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [noJual, setNoJual] = useState("");
  const [jumlahBeli, setJumlahBeli] = useState("");

  const [tipes, setTipes] = useState([]);
  const [warnas, setWarnas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const [openAlertNopol, setOpenAlertNopol] = useState(false);
  const [openAlertNorang, setOpenAlertNorang] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClickOpenAlertNopol = () => {
    setOpenAlertNopol(true);
  };

  const handleCloseAlertNopol = () => {
    setOpenAlertNopol(false);
  };

  const handleClickOpenAlertNorang = () => {
    setOpenAlertNorang(true);
  };

  const handleCloseAlertNorang = () => {
    setOpenAlertNorang(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const tipeOptions = tipes.map((tipe) => ({
    label: `${tipe.kodeTipe} | ${tipe.namaTipe}`,
  }));

  const warnaOptions = warnas.map((warna) => ({
    label: `${warna.namaWarna}`,
  }));

  useEffect(() => {
    getTipes();
    getWarnas();
    getUserById();
  }, []);

  const getTipes = async () => {
    setLoading(true);
    const allTipes = await axios.post(`${tempUrl}/tipes`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setTipes(allTipes.data);
    setLoading(false);
  };

  const getTipe = async (idTipe) => {
    const allTipesByKode = await axios.post(`${tempUrl}/tipesByKode`, {
      kodeTipe: idTipe,
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    if (allTipesByKode.data) {
      setNoRangka(allTipesByKode.data.noRangka);
      setNoMesin(allTipesByKode.data.noMesin);
    }
    setKodeTipe(idTipe);
  };

  const getWarnas = async () => {
    const allWarnas = await axios.post(`${tempUrl}/warnas`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setWarnas(allWarnas.data);
  };

  const getUserById = async () => {
    setLoading(true);
    if (id) {
      const beli = await axios.post(`${tempUrl}/belis/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setJumlahBeli(beli.data.jumlahBeli);

      if (beli.data.isPost) {
        navigate(`/daftarBeli/beli/${id}/${idBeliChild}`);
      }

      const response = await axios.post(
        `${tempUrl}/daftarStoks/${idBeliChild}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setNoBeli(response.data.noBeli);
      setKodeTipe(response.data.tipe);
      setKodeTipeLama(response.data.tipe);
      setTahun(response.data.tahun);
      setNamaWarna(response.data.namaWarna);
      setNamaWarnaLama(response.data.namaWarna);
      setNoRangka(response.data.noRangka);
      setNoRangkaLama(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNoMesinLama(response.data.noMesin);
      setNopol(response.data.nopol);
      setNopolLama(response.data.nopol);
      setNamaStnk(response.data.namaStnk);
      setAlamatStnk(response.data.alamatStnk);
      setTglStnk(new Date(response.data.tglStnk));
      setJenisABeli(response.data.jenisBeli);
      setHargaSatuan(response.data.hargaSatuan.toLocaleString("en-US"));
      setHargaSatuanLama(response.data.hargaSatuan.toLocaleString("en-US"));
      setPpnABeli(response.data.ppnABeli);
      setTanggalJual(formatDate(response.data.tanggalJual));
      setNoJual(response.data.noJual);
    }
    setLoading(false);
  };

  const updateBeliChild = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      kodeTipe.length === 0 ||
      tahun.length === 0 ||
      namaWarna.length === 0 ||
      noRangka.length === 0 ||
      noMesin.length === 0 ||
      nopol.length === 0 ||
      namaStnk.length === 0 ||
      alamatStnk.length === 0 ||
      tglStnk === null ||
      hargaSatuan.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);

        if (noJual.length > 0) {
          if (
            window.confirm(
              `Stok sudah terjual dengan No. Jual ${noJual}. Yakin ingin ubah?`
            )
          ) {
            // Save it!
            if (nopol !== nopolLama) {
              // Check Nopol
              let tempDaftarStokByNopol = await axios.post(
                `${tempUrl}/daftarStoksByNopol`,
                {
                  nopol,
                  id: user._id,
                  token: user.token,
                  kodeCabang: user.cabang._id,
                }
              );
              let adaNopolDanSudahTerjual =
                tempDaftarStokByNopol.data &&
                tempDaftarStokByNopol.data.tanggalJual !== null;
              let adaNopolDanBelumTerjual =
                tempDaftarStokByNopol.data &&
                tempDaftarStokByNopol.data.tanggalJual === null;
              if (adaNopolDanSudahTerjual) {
                // Ada Nopol sama dan Sudah Terjual, tambah titik di Nopol
                nopol = `${nopol}.`;
              } else if (adaNopolDanBelumTerjual) {
                // Ada Nopol sama dan Belum Terjual, tidak boleh tambah Daftar Stok
                handleClickOpenAlertNopol();
                setLoading(false);
                return;
              }
            }

            if (noRangka !== noRangkaLama) {
              // Check Norang
              let tempDaftarStokByNorang = await axios.post(
                `${tempUrl}/daftarStoksByNorang`,
                {
                  noRangka,
                  id: user._id,
                  token: user.token,
                  kodeCabang: user.cabang._id,
                }
              );
              let adaNorang = tempDaftarStokByNorang.data;
              if (adaNorang) {
                // Ada sama di Daftar Stok
                handleClickOpenAlertNorang();
                setLoading(false);
                return;
              }
            }

            // Get Tipe/Merk
            const findTipe = await axios.post(`${tempUrl}/tipesByKode`, {
              kodeCabang: user.cabang._id,
              kodeTipe,
              id: user._id,
              token: user.token,
            });

            let tempJumlahBeli =
              jumlahBeli -
              parseInt(hargaSatuanLama.replace(/,/g, "")) +
              parseInt(hargaSatuan.replace(/,/g, ""));

            // Update Daftar Stok And Update Beli
            await axios.post(`${tempUrl}/updateDaftarStokAndUpdateBeli`, {
              daftarStokData: {
                idDaftarStok: idBeliChild,
                merk: findTipe.data.merk,
                tipe: findTipe.data.kodeTipe,
                namaTipe: findTipe.data.namaTipe,
                tahun,
                namaWarna,
                noRangka,
                noMesin,
                nopol,
                namaStnk,
                alamatStnk,
                tglStnk,
                hargaSatuan: hargaSatuan.replace(/,/g, ""),
                tglUpdate: current_date,
                jamUpdate: current_time,
                userUpdate: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              jualData: {
                merk: findTipe.data.merk,
                tipe: findTipe.data.kodeTipe,
                tahun,
                namaWarna,
                noRangka,
                noMesin,
                nopol,
                namaStnk,
                alamatStnk,
                tglStnk,
                noJual,
                tglUpdate: current_date,
                jamUpdate: current_time,
                userUpdate: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              beliData: {
                idBeli: id,
                jumlahBeli: tempJumlahBeli,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              tglUpdate: current_date,
              jamUpdate: current_time,
              userUpdate: user.username,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            });
          }
        } else {
          if (nopol !== nopolLama) {
            // Check Nopol
            let tempDaftarStokByNopol = await axios.post(
              `${tempUrl}/daftarStoksByNopol`,
              {
                nopol,
                id: user._id,
                token: user.token,
                kodeCabang: user.cabang._id,
              }
            );
            let adaNopolDanSudahTerjual =
              tempDaftarStokByNopol.data &&
              tempDaftarStokByNopol.data.tanggalJual !== null;
            let adaNopolDanBelumTerjual =
              tempDaftarStokByNopol.data &&
              tempDaftarStokByNopol.data.tanggalJual === null;
            if (adaNopolDanSudahTerjual) {
              // Ada Nopol sama dan Sudah Terjual, tambah titik di Nopol
              nopol = `${nopol}.`;
            } else if (adaNopolDanBelumTerjual) {
              // Ada Nopol sama dan Belum Terjual, tidak boleh tambah Daftar Stok
              handleClickOpenAlertNopol();
              setLoading(false);
              return;
            }
          }

          if (noRangka !== noRangkaLama) {
            // Check Norang
            let tempDaftarStokByNorang = await axios.post(
              `${tempUrl}/daftarStoksByNorang`,
              {
                noRangka,
                id: user._id,
                token: user.token,
                kodeCabang: user.cabang._id,
              }
            );
            let adaNorang = tempDaftarStokByNorang.data;
            if (adaNorang) {
              // Ada sama di Daftar Stok
              handleClickOpenAlertNorang();
              setLoading(false);
              return;
            }
          }

          // Get Tipe/Merk
          const findTipe = await axios.post(`${tempUrl}/tipesByKode`, {
            kodeCabang: user.cabang._id,
            kodeTipe,
            id: user._id,
            token: user.token,
          });

          let tempJumlahBeli =
            jumlahBeli -
            parseInt(hargaSatuanLama.replace(/,/g, "")) +
            parseInt(hargaSatuan.replace(/,/g, ""));

          // Update Daftar Stok And Update Beli
          await axios.post(`${tempUrl}/updateDaftarStokAndUpdateBeli`, {
            daftarStokData: {
              idDaftarStok: idBeliChild,
              merk: findTipe.data.merk,
              tipe: findTipe.data.kodeTipe,
              namaTipe: findTipe.data.namaTipe,
              tahun,
              namaWarna,
              noRangka,
              noMesin,
              nopol,
              namaStnk,
              alamatStnk,
              tglStnk,
              hargaSatuan: hargaSatuan.replace(/,/g, ""),
              tglUpdate: current_date,
              jamUpdate: current_time,
              userUpdate: user.username,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            },
            beliData: {
              idBeli: id,
              jumlahBeli: tempJumlahBeli,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            },
            tglUpdate: current_date,
            jamUpdate: current_time,
            userUpdate: user.username,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
        }

        setLoading(false);
        navigate(`/daftarBeli/beli/${id}/${idBeliChild}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        <Typography color="#757575">Pembelian</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Ubah Barang Beli
        </Typography>
        <Dialog
          open={openAlertNopol}
          onClose={handleCloseAlertNopol}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Nopol Sama`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Nopol ${nopol} sudah ada dan belum terjual, ganti Nopol!`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlertNopol}>Ok</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAlertNorang}
          onClose={handleCloseAlertNorang}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Data No Rangka Sama`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`No Rangka ${noRangka} sudah ada, ganti No Rangka!`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlertNorang}>Ok</Button>
          </DialogActions>
        </Dialog>
        <Divider sx={dividerStyle} />
        <Paper sx={contentContainer} elevation={12}>
          <Form noValidate validated={validated} onSubmit={updateBeliChild}>
            <Box sx={[textFieldContainer, spacingTop]}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No Beli :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noBeli} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Tipe <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={tipeOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeTipe.length === 0 && true}
                              helperText={
                                error &&
                                kodeTipe.length === 0 &&
                                "Kode Tipe harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            console.log(value);
                            if (value) {
                              getTipe(`${value.split(" |", 1)[0]}`);
                            } else {
                              setKodeTipe("");
                              setNoRangka("");
                              setNoMesin("");
                            }
                          }}
                          inputValue={kodeTipe}
                          value={kodeTipeLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={tahun}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 4) {
                              setTahun(e.target.value.toUpperCase());
                            }
                          }}
                          required
                          isInvalid={tahun.length != 4 && true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Tahun harus diisi dan harus 4 digit angka!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Warna <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={warnaOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && namaWarna.length === 0 && true}
                              helperText={
                                error &&
                                namaWarna.length === 0 &&
                                "Nama Warna harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setNamaWarna(value)}
                          inputValue={namaWarna}
                          onChange={(e, value) => setNamaWarnaLama(value)}
                          value={namaWarnaLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nomor Rangka <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noRangka}
                          required
                          onChange={(e) =>
                            setNoRangka(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nomor Rangka harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No Mesin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noMesin}
                          required
                          onChange={(e) =>
                            setNoMesin(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nomor Mesin harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nopol <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={nopol}
                          required
                          onChange={(e) =>
                            setNopol(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nopol harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama STNK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaStnk}
                          required
                          onChange={(e) =>
                            setNamaStnk(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama STNK harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat STNK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={alamatStnk}
                          required
                          onChange={(e) =>
                            setAlamatStnk(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Alamat STNK harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal STNK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={tglStnk}
                          onChange={(e) => setTglStnk(e)}
                          customInput={
                            <TextField
                              error={error && tglStnk === null && true}
                              helperText={
                                error &&
                                tglStnk === null &&
                                "Tanggal STNK harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Tgl. STNK harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={jenisABeli} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Harga Satuan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={hargaSatuan}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaSatuan(tempNum);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Harga Satuan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        PPN :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={ppnABeli.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tanggalJual} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noJual} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate(`/daftarBeli/beli/${id}/${idBeliChild}`)
                }
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Ubah
              </Button>
            </Box>
          </Form>
        </Paper>
        {error && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={alertBox}>
              Data belum terisi semua!
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
};

export default UbahBeliChild;

const subTitleText = {
  fontWeight: "900",
};

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const colorRed = {
  color: "red",
};

const alertBox = {
  width: "100%",
};
