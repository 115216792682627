import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTablePenarikan } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";

const TampilPenarikan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isPenarikansExist, setIsPenarikansExist] = useState(false);

  // Customer
  const [noPenarikan, setNoPenarikan] = useState(""); // I
  const [tglPenarikan, setTglPenarikan] = useState("");
  const [noJual, setNoJual] = useState(""); // I

  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  // Motor
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [nopol, setNopol] = useState("");
  // Biaya
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoFormatted, setTglJatuhTempoFormatted] = useState("");
  const [sisaBulanPenarikan, setSisaBulanPenarikan] = useState(""); // I
  const [tenor, setTenor] = useState(""); // I
  const [ketPenarikan, setKetPenarikan] = useState(""); // I

  const [searchTerm, setSearchTerm] = useState("");
  const [stsData, setStsData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [dariTgl, setDariTgl] = useState(new Date());
  const [sampaiTgl, setSampaiTgl] = useState(new Date());
  const [toggleCetak, setToggleCetak] = useState(false);

  const columnsCetak = [
    { title: "No.", field: "no" },
    { title: "No. Jual", field: "noJual" },
    { title: "No. Penarikan", field: "noPenarikan" },
    { title: "Tgl. Penarikan", field: "tglPenarikanFormatted" },
    { title: "Sisa Bulan", field: "sisaBulanPenarikan" },
  ];

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getPenarikanById() : setIsPenarikansExist(false);
  }, [id]);

  useEffect(() => {
    if (user.tipeUser === "OWN/DIREKSI") {
      getPenarikanAllCabangsData();
    } else {
      getPenarikansData();
    }
  }, [page, searchTerm]);

  const getPenarikanAllCabangsData = async () => {
    setLoading(true);
    try {
      const allSts = await axios.post(
        `${tempUrl}/penarikanAllCabangsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setStsData(allSts.data.penarikans);
      setPage(allSts.data.page);
      setPages(allSts.data.totalPage);
      setRows(allSts.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPenarikansData = async () => {
    setLoading(true);
    try {
      const allSts = await axios.post(
        `${tempUrl}/penarikansPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setQuery(searchTerm);
      setStsData(allSts.data.penarikans);
      setPage(allSts.data.page);
      setPages(allSts.data.totalPage);
      setRows(allSts.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPenarikanById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/penarikans/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsPenarikansExist(true);
      setNoPenarikan(response.data.noPenarikan);
      setTglPenarikan(formatDate(response.data.tglPenarikan));
      setNoJual(response.data.noJual);
      setSisaBulanPenarikan(response.data.sisaBulanPenarikan);
      setKetPenarikan(response.data.ketPenarikan);

      setNamaRegister(response.data.idJual.namaRegister);
      setAlmRegister(response.data.idJual.almRegister);
      setTglAng(response.data.idJual.tglJatuhTempo);
      setTglAngFormatted(formatDate(response.data.idJual.tglJatuhTempo));
      setKodeKelurahan(
        `${response.data.idJual.kodeKelurahan.kodeKelurahan} - ${response.data.idJual.kodeKelurahan.namaKelurahan}`
      );
      setTlpRegister(response.data.idJual.tlpRegister);
      setTipe(response.data.idJual.tipe);
      setNoRangka(response.data.idJual.noRangka);
      setNamaWarna(response.data.idJual.namaWarna);
      setTahun(response.data.idJual.tahun);
      setNopol(response.data.idJual.nopol);
      setTglJatuhTempo(response.data.idJual.tglJatuhTempo);
      setTenor(response.data.idJual.tenor - response.data.sisaBulanPenarikan);
      setTglJatuhTempoFormatted(formatDate(response.data.idJual.tglJatuhTempo));
    }
    setLoading(false);
  };

  const deletePenarikan = async (id) => {
    try {
      setLoading(true);

      // Find Jual
      const findJualByNoJual = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      // Delete Penarikan Transaction
      await axios.post(`${tempUrl}/deletePenarikanTransaction`, {
        penarikanData: {
          idPenarikan: id,
          id: user._id,
          token: user.token,
        },
        jualData: {
          idJual: findJualByNoJual.data._id,
          isTarik: false,
          noPenarikan: "",
          tglPenarikan: "",
          idPenarikan: "",
          ketPenarikan: "",
          sisaBulan: sisaBulanPenarikan,
          sisaBulanPenarikan: 0,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        titipData: {
          idJual: findJualByNoJual.data._id,
          isTarik: false,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        sweepingData: {
          idJual: findJualByNoJual.data._id,
          isTarik: false,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        stData: {
          idJual: findJualByNoJual.data._id,
          isTarik: false,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        daftarStokData: {
          idPenarikan: id,
          id: user._id,
          token: user.token,
        },
        angsuranData: {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        },
        id: user._id,
        token: user.token,
      });

      setNoPenarikan("");
      setTglPenarikan("");
      setNoJual("");
      setSisaBulanPenarikan("");
      setKetPenarikan("");
      setLoading(false);
      navigate("/penarikan");
      getPenarikansData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdfCetak = async () => {
    setLoading(true);
    let allPenarikans = await axios.post(`${tempUrl}/penarikansTanggal`, {
      dariTgl,
      sampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Penarikan`, 95, 30);
    doc.setFontSize(10);

    function alignCol(data) {
      var col = data.column.index;
      if (col == 4) {
        data.cell.styles.halign = "right";
      }
    }
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columnsCetak.map((col) => ({ ...col, dataKey: col.field })),
      body: allPenarikans.data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });

    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Penarikan
      </Typography>
      <Box sx={downloadButtons}>
        {user.tipeUser !== "OWN/DIREKSI" && (
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              variant="contained"
              onClick={() => setToggleCetak(!toggleCetak)}
            >
              Cetak
            </Button>
          </ButtonGroup>
        )}
      </Box>
      {toggleCetak && (
        <>
          <Box sx={spacingTop}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Dari Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={dariTgl}
                      onChange={(e) => setDariTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sampai Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTgl}
                      onChange={(e) => setSampaiTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                  <Col sm="4">
                    <Button
                      variant="contained"
                      startIcon={<PrintIcon />}
                      onClick={() => {
                        if (dariTgl === null || sampaiTgl == null) {
                          alert("Input Tanggal harus diisi!");
                        } else {
                          downloadPdfCetak();
                        }
                      }}
                    >
                      CETAK
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <hr />
        </>
      )}
      <Box sx={buttonModifierContainer}>
        {user.tipeUser !== "OWN/DIREKSI" && (
          <ButtonModifier
            id={id}
            kode={isPenarikansExist}
            addLink={`/penarikan/tambahPenarikan`}
            editLink={`/penarikan/${id}/edit`}
            deleteUser={deletePenarikan}
            nameUser={noPenarikan}
          />
        )}
      </Box>
      <Divider sx={dividerStyle} />
      {isPenarikansExist && (
        <>
          <hr />
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA CUSTOMER
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noPenarikan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglPenarikan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kelurahan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeKelurahan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Angsuran :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglAngFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <hr />
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA MOTOR
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun / Warna :
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={tahun} disabled />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <hr />
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA PENARIKAN
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Jatuh Tempo:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglJatuhTempoFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Sisa Bulan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={sisaBulanPenarikan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tenor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tenor.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={ketPenarikan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}></Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTablePenarikan currentPosts={stsData} tipeUser={user.tipeUser} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilPenarikan;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};
